<template>
  <!-- <div>
    <div>
      <h2>Vue Js Search and Add Marker</h2>

      <label>
        <gmap-autocomplete @place_changed="initMarker"></gmap-autocomplete>

        <button @click="addLocationMarker">Add</button>
      </label>
      <br/>
 
    </div>
    <br> -->
  <gmap-map :zoom="12" :center="center" ref="mapRef" style="width: 100%; height: 600px" 
      :options="mapStyle">
    <gmap-marker
      :key="index"
      v-for="(m, index) in locationMarkers"
      :position="m.position"
      @click="openMarker(m.id)"
    >
      <gmap-info-window
        :closeclick="true"
        @closeclick="openMarker(null)"
        :opened="openedMarkerID === m.id"
      >
        <div>{{ m.name }}</div>
      </gmap-info-window>
    </gmap-marker>
  </gmap-map>
  <!-- </div> -->
</template>
 
<script>
import axios from "axios";
export default {
  name: "AddGoogleMap",
  data() {
    return {
      mapStyle: {
        // other properties... 
        styles: [
            // {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            // {elementType: 'labels.text.stroke', stylers: [{color: '#f'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {
                featureType: 'road',
                elementType: 'geometry',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
              stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
              stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
              stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
              stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
              stylers: [{ visibility: 'off' }],
            },
            // {
            //     featureType: 'water',
            //     elementType: 'geometry',
            //     stylers: [{color: '#17263c'}]
            // },
            // {
            //     featureType: 'water',
            //     elementType: 'labels.text.fill',
            //     stylers: [{color: '#515c6d'}]
            // },
            // {
            //     featureType: 'water',
            //     elementType: 'labels.text.stroke',
            //     stylers: [{color: '#17263c'}]
            // }
        ]
      },
      center: {
        lat: 0.8851261,
        lng: 108.9814298,
      },
      openedMarkerID: null,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },
  mounted() {
    this.loadData();
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.data.loadGeoJson('https://sippd.probowsolution.com/assets/jalan_singkawang.geojson');
    })
  },

  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    addLocationMarker(id, nama, latt, long) {
      // if (this.existingPlace) {
      // const marker = {
      //   lat: this.existingPlace.geometry.location.lat(),
      //   lng: this.existingPlace.geometry.location.lng()
      // };

      const marker = {
        lat: +latt,
        lng: +long,
      };
      this.locationMarkers.push({ position: marker, id: id, name: nama });
      this.locPlaces.push(this.existingPlace);
      // this.center = marker;
      // this.existingPlace = null;
      // }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    loadData: function () {
      // if (this.tahun == null) {
      // your code here.
      // axios
      // .get("https://probowsolution.com/sippd/api/public/index.php/pekerjaan/2021?key=704b037a97fa9b25522b7c014c300f8a")
      // .then((response)=>
      // {
      // this.products = response.data;
      // })
      // } else {
      axios
        .get(
          "https://sippd.probowsolution.com/api/public/index.php/pekerjaanmap/2021?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
        )
        .then((response) => {
          this.locPlaces = response.data;
          Object.values(this.locPlaces).forEach((item) =>
            this.addLocationMarker(
              item.id,
              item.nama_pekerjaan,
              item.lattitude,
              item.longitude
            )
          );

          // for (property in Object.values(this.locPlaces)){
          //   // this.form.examiner_id.push(this.locPlaces[property].id);
          //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
          // }
        });
      // }
    },
  },
};
</script>